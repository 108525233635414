<template>
  <v-container fluid>
    <v-row>
      <v-form ref="form" autocomplete="off">
        <v-col>
          <address-picker :detail-mode="true" :min-characters="4" v-model="location"></address-picker>
        </v-col>
        <v-col>Value:{{location}}</v-col>
      </v-form>
    </v-row>
  </v-container>
</template>


<script>
import AddressPicker from "../components/core/AddressPicker";

export default {
  name: "addressWrapper",
  components: {
    AddressPicker
  },
  data: () => ({
    location: null,
    form: {}
  }),

  mounted() {
    this.init();
  },
  computed: {},

  methods: {
    init() {
      this.location = "test123";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
</style>

